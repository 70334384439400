import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Context
import { PageContext } from "../components/context/page-context";

// Components
import { Gallery } from "../components/images/gallery";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { VideoWithControls } from "../components/videos/video";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

const Page = styled.div`
  & .spacer {
    & .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    & .right {
      background-color: #ebd4dd;
      padding: 110px 30px 3em 30px;
    }
  }

  & .single-article {
    & .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    & .image-container {
      position: relative;
      height: 100%;
      width: 50vw;

      padding: 0 30px 2em 30px;

      & .slide {
        overflow: hidden;
        padding: 0 0 30px 0;

        & img {
          width: 100%;
          height: 100%;

          &.image-landscape {
            object-fit: cover;
          }

          &.image-portrait {
            object-fit: contain;
          }
        }

        @media (max-width: 767px) {
          padding: 0 0 15px 0;
        }
      }
    }

    & .text-container {
      background-color: #ebd4dd;
      padding: 0 30px 3em 30px;

      & .article-date {
        margin: 0;
      }

      & p {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  @media (max-width: 767px) {
    background-color: #ebd4dd;

    & .mobile-title-container {
      display: grid;
      grid-template-columns: 1fr 90px;

      padding: 0 15px 25px 15px;

      & .article-date {
        text-align: right;
        margin: 0;
      }
    }

    & .single-article {
      & .grid {
        display: block;
        grid-template-columns: unset;
      }

      & .image-container {
        grid-column: unset;
        width: 100%;
      }

      & .image-container {
        padding: 15px 15px 0 15px;
      }

      & .text-container {
        padding: 0 15px 55px 15px;
      }

      &:first-of-type {
        padding: 50px 0;

        & .image-container,
        & .text-container {
          padding: 0 15px;
          // 15px 15px
        }
      }
    }
  }
`;

const News = ({ data, location }) => {
  const [pageContext, setPageContext] = useContext(PageContext);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    setPageContext({
      pageTitle: `News`,
      pageColor: `#EBD4DD`,
      pageType: `news`,
    });
  }, []);

  useEffect(() => {
    if (
      location !== null &&
      location.state !== null &&
      location.state !== undefined
    ) {
      if (location.state.id !== null && location.state.id !== undefined) {
        const articleId = location.state.id;

        if (typeof window !== `undefined`) {
          let dims = document
            .querySelector(`#article-id-${articleId}`)
            .getBoundingClientRect();
          window.scrollTo({ top: dims.top - 70, behavior: "smooth" });
        }
      }
    } else {
      return null;
    }
  }, [location]);

  //https://stackoverflow.com/questions/51009090/sort-and-group-objects-alphabetically-by-first-letter-javascript
  const groupedArticles = data.allPrismicNewsArticle.nodes.reduce((r, e) => {
    // get first letter of name of current element
    let group = e.data.year;
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, children: [e] };
    // if there is push current element to children array for that letter
    else r[group].children.push(e);
    // return accumulator
    return r;
  }, {});

  // since data at this point is an object, to get array of values
  // we use Object.values method
  const formattedArticles = Object.values(groupedArticles);

  const content = formattedArticles.reverse().map((group, groupIndex) => {
    const articles = group.children.map((article, index) => {
      const content = article.data.body.map((item, index) => {
        if (item.slice_type === "image") {
          return (
            <div
              className="slide"
              key={`single_image_slide_${index}_${article.id}`}
            >
              <AspectRatioImageContainer image={null} padding={75}>
                <img
                  className={`image-${ImageOrientation(item.primary.image)}`}
                  srcSet={item.primary.image.fluid.srcSet}
                  src={item.primary.image.fluid.src}
                  alt={item.primary.image.alt}
                />
              </AspectRatioImageContainer>
            </div>
          );
        }

        if (item.slice_type === "video") {
          return (
            <div
              className="slide video"
              key={`single_video_slide_${index}_${article.id}`}
            >
              <VideoWithControls content={item} />
            </div>
          );
        }

        if (item.slice_type === "gallery") {
          const galleryContent = item.items.map((innerItem, innerIndex) => (
            <div className="" key={`single_image_slide_${innerIndex}_${index}`}>
              <AspectRatioImageContainer image={null} padding={75}>
                <img
                  className={`image-${ImageOrientation(innerItem.image)}`}
                  srcSet={innerItem.image.fluid.srcSet}
                  src={innerItem.image.fluid.src}
                  alt={innerItem.image.alt}
                  loading={`lazy`}
                />
              </AspectRatioImageContainer>
            </div>
          ));

          return (
            <Gallery
              galleryContent={galleryContent}
              key={`single_image_gallery_${index}`}
              color={`#EBD4DD`}
            />
          );
        }
      });

      return (
        <div
          className="single-article"
          key={`single_article_${index}`}
          id={`article-id-${article.id}`}
        >
          <div className="grid">
            {!isDesktop && (
              <div className="mobile-title-container">
                <PrismicRichText field={article.data.title.richText} />
                <p className="article-date">{article.data.date}</p>
              </div>
            )}

            <div className="image-container">{content}</div>

            <div className="text-container">
              {isDesktop && (
                <>
                  <PrismicRichText field={article.data.title.richText} />
                  <p className="article-date">{article.data.date}</p>
                </>
              )}

              <PrismicRichText field={article.data.text.richText} />
            </div>
          </div>
        </div>
      );
    });

    return (
      <div
        className=""
        id={group.group}
        key={`single_year_${group.group}_${groupIndex}`}
      >
        {articles}
      </div>
    );
  });

  return (
    <>
      <PageSeo
        seoTitle={`News - Jamie Whelan Studio`}
        seoText={null}
        seoImage={null}
      />
      <Page>
        <div className="spacer">
          <div className="grid">
            <div className="left"></div>
            <div className="right"></div>
          </div>
        </div>
        {content}
      </Page>
    </>
  );
};

export default News;

export const query = graphql`
  {
    allPrismicNewsArticle(sort: { order: DESC, fields: data___date }) {
      nodes {
        id
        data {
          title {
            richText
          }
          text {
            richText
          }
          date(formatString: "DD.MM.YY")
          year: date(formatString: "YYYY")
          body {
            ... on PrismicNewsArticleDataBodyImage {
              id
              slice_type
              primary {
                image {
                  alt
                  fluid {
                    aspectRatio
                    src
                    srcSet
                  }
                  dimensions {
                    height
                    width
                  }
                }
              }
            }

            ... on PrismicNewsArticleDataBodyGallery {
              id
              slice_type
              primary {
                full_width
              }
              items {
                image {
                  dimensions {
                    height
                    width
                  }
                  alt
                  fluid {
                    srcSet
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// ... on PrismicNewsArticleDataBodyVideo {
//               id
//               slice_type
//               primary {
//                 video {
//                   id
//                   height
//                   width
//                   thumbnail_url
//                   embed_url
//                   html
//                 }
//               }
//             }
